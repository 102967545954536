import React from "react"
import { Link } from "gatsby"

import "./miniheader.scss"
import "../../pages/index.scss"

import ImgLogo from "../../images/logo-tanecni-zlonin.svg"

const MiniHeader = (props) => (

	<div className="tz-miniheader">

		<div className="container tz-miniheader__container">

			<Link to="/" data-sal="fade" data-sal-duration="1000">
				<img src={ImgLogo} alt="Taneční Zlonín logo" className="tz-miniheader__logo" />
			</Link>

			<h1 className="tz-miniheader__h1" data-sal="fade" data-sal-duration="1000">
				{
					props.text === 'tanecni' ? "Taneční pro\u00A0dospělé" 
					: props.text === 'fever' ? "Latin Fever for\u00A0ladies" 
					: props.text === 'tancirna' ? "Nedělní tančírna" 
					: props.text === 'pripravka' ? <>Taneční přípravka pro&nbsp;děti <span className="nowrap">5-6</span>&nbsp;let</> 
					: props.text === 'kurz' ? <>Taneční kurz pro&nbsp;děti <span className="nowrap">7-12</span>&nbsp;let</> 
					: "Taneční Zlonín"
				}
			</h1>

		</div>

	</div>

)

export default MiniHeader