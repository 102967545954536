import React, { useEffect } from "react"

import { Link } from "gatsby"

import "../course/course.scss"
import "../../pages/index.scss"

const Kurz = () => {

	useEffect(() => {
		document.body.classList.add("specialBackground");
		document.body.classList.add("kurzBackground");
		return () => {
			document.body.classList.remove("specialBackground");
			document.body.classList.remove("kurzBackground");
		}
	}, []);
	
	return (

		<div className="tz-course">

			<div className="container mb3">

				<Link to="/prihlaska-deti?kurz" data-sal="fade" data-sal-duration="1000" className="tz-button tz-button--big tz-button--inverse mb1">on-line přihláška zde</Link>
				<h2 className="mt1 mb3 w50" data-sal="fade" data-sal-duration="1000">Standardní a latinsko-americké tance pro&nbsp;holky a&nbsp;kluky pod&nbsp;vedením profesionálů</h2>

				<table className="tz-course__kids-table" data-sal="fade" data-sal-duration="1000">
					<tbody>
					<tr>
							<th>děti - starší skupina</th>
							<td>7-12 let</td>
							<td>pondělí 17:15-18:15</td>
							<td>malý sál</td>
						</tr>
						<tr>
							<th>děti - starší skupina</th>
							<td>7-12 let</td>
							<td>středa 15:00-16:00</td>
							<td>velký sál</td>
						</tr>
					</tbody>
				</table>

				<div data-sal="fade" data-sal-duration="1000" className="mb3">
					<h3 className="mb1">Taneční kurzy pro&nbsp;děti&nbsp;🙋‍♀️🙋‍♂️</h3>
					<p className="mb0"><strong>Začínáme již ve&nbsp;středu 4.&nbsp;září&nbsp;2024&nbsp;👈</strong></p>
					<p className="mb1">2x týdně v KC Zlonín</p>
					<h3 className="mb0"><strong>Cena: 4.000,- / pololetí</strong></h3>
					<p className="mb1">možnost slevy 20% v&nbsp;případě druhého sourozence</p>
					<ul className="tz-list">
						<li className="tz-list__item highlighted-text"><span>✅ Taneční a pohybová průprava</span></li>
						<li className="tz-list__item highlighted-text"><span>✅ Základy standardních a&nbsp;latinsko-amerických tanců</span></li>
						<li className="tz-list__item highlighted-text"><span>✅ Rozvoj rytmiky a&nbsp;koordinace pohybu</span></li>
						<li className="tz-list__item highlighted-text"><span>✅ Soutěže a vystoupení</span></li>
					</ul>
					<p className="mt2 mb0">Kurzy povede profesionální tanečnice a&nbsp;trenérka Irina Maizlish 💃🕺</p>
					<p>Možnost pokračovat soutěžním sportovním tancem 🏅🏆</p>
				</div>

				<div data-sal="fade" data-sal-duration="1000">
					<h2>Přihlašte své děti do&nbsp;tanečního kurzu:</h2>
					<ul className="tz-list">
						<li className="tz-list__item highlighted-text"><span>✅ online <Link className="tz-list__link" to="/prihlaska-deti?kurz">prihláška zde</Link></span></li>
						<li className="tz-list__item highlighted-text"><span>✅ na emailu <a className="tz-list__link" href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></span></li>
						<li className="tz-list__item highlighted-text"><span>✅ na messengeru stránky <a className="tz-list__link" href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční Zlonín</a></span></li>
						<li className="tz-list__item highlighted-text"><span>✅ na telefonu/WhatsApp <a className="tz-list__link" href="tel:+420732560838">+420 732 560 838</a></span></li>
					</ul>
				</div>

			</div>

		</div>

	)
}

export default Kurz