import React from "react"

import "./divider.scss"

const Divider = () => (

	<div className="tz-divider"></div>

)

export default Divider